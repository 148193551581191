import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid } from 'semantic-ui-react'
import { Container } from '@mch-group/uikit-components'
import { CustomLinkTag } from '@components'
import { detectDpr } from '@utils'

import Text from '../../components/Text'
import cssClasses from '../../constants/cssClasses'
import LazyImage from '../Image'

const mID = 'rm-signpost'

const isEmpty = (props) => (props.image || props.title || props.subTitle || props.body ? '' : `${mID}__empty`)

const RmSignpostView = (props) => (
  <>
    <div className={`${mID}-image__wrapper`}>
      {props.image && (
        <LazyImage
          publicId={props.image}
          aspect_ratio='16:10'
          crop='fill'
          // https://www.w3.org/WAI/tutorials/images/decorative/#example-2-decorative-image-as-part-of-a-text-link
          alt=''
          responsive
          dpr={detectDpr('dpr_')}
          quality='auto'
        />
      )}
    </div>
    <div className='pt-md-7 pt-5 px-md-8 px-6 pb-md-8 pb-6'>
      <div className='mb-5'>
        {props.title && <h2 className='h3'>{props.title}</h2>}
        {props.subTitle && (
          <div className='text-paragraph-2 h4'>
            {props.subTitle}
          </div>
        )}
      </div>
      {props.body && (
        <Text className='text-medium' isHtml>
          {props.body}
        </Text>
      )}
    </div>
  </>
)

const RmSignpostContent = (props) => (
  <article className={`${isEmpty(props)} mb-md-15 mb-lg-0 mb-sm-9`}>
    <CustomLinkTag page='/' url={props.cta_href} className='mb-11 mb-lg-9'>
      <RmSignpostView {...props} />
    </CustomLinkTag>
  </article>
)

const RmSignpostItem = props => (
  //@ts-ignore
  <Grid.Column mobile='12' computer={props.layout === 1 ? 8 : 12 / props.layout} className='wrapper'>
    <RmSignpostContent {...props} />
  </Grid.Column>
)

const RmSignpost = ({ className, ...props }) => {
  const columnNumber = parseInt(props.signpost_grp1_column_num, 10) || 0
  let formatedObject = []
  //@ts-ignore
  formatedObject = new Array(columnNumber).fill().map((key, i) => {
    const number = i + 1
    return {
      title: props[`signpost_grp${number}_title`],
      subTitle: props[`signpost_grp${number}_subtitle`],
      image: props[`signpost_grp${number}_img`],
      body: props[`signpost_grp${number}_body`],
      cta_href: props[`signpost_grp${number}_cta_href`],
      index: i
    }
  })

  return (
    <div className={`${className} ${cssClasses.CMS_MODULE}`}>
      <div className={`${mID}`}>
        <Container className='pt-lg-12 pb-lg-8 px-lg-8 px-md-7 px-sm-5 pt-md-11 pt-sm-9'>
          <Grid padded='vertically'>
            <Grid.Row stretched>
              {formatedObject.map(item => (
                //@ts-ignore
                <RmSignpostItem {...item} layout={columnNumber} key={item.index} />
              ))}
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    </div>
  )
}

RmSignpostContent.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  image: PropTypes.string,
  body: PropTypes.string,
  cta_href: PropTypes.string,
  layout: PropTypes.number
}

RmSignpostItem.propTypes = {
  layout: PropTypes.number
}

RmSignpostView.propTypes = {
  cta_href: PropTypes.string,
  layout: PropTypes.number,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  image: PropTypes.string,
  body: PropTypes.string
}

RmSignpost.propTypes = {
  signpost_grp1_title: PropTypes.string,
  signpost_grp1_subtitle: PropTypes.string,
  signpost_grp1_img: PropTypes.string,
  signpost_grp1_body: PropTypes.string,
  signpost_grp1_cta_label1: PropTypes.string,
  signpost_grp1_cta_href: PropTypes.string,
  signpost_grp2_title: PropTypes.string,
  signpost_grp2_subtitle: PropTypes.string,
  signpost_grp2_img: PropTypes.string,
  signpost_grp2_body: PropTypes.string,
  signpost_grp2_cta_label: PropTypes.string,
  signpost_grp2_cta_href: PropTypes.string,
  className: PropTypes.string,
  signpost_grp1_column_num: PropTypes.string,
  id: PropTypes.number
}

const StyledRmSignpost = styled(RmSignpost)`
  background-color: var(--bs-mch-gray-100);

  .${mID} {
    &-image__wrapper {
      position: relative;
      padding-bottom: 62.5%;
    }
  }

  a {
    @media (min-width: 767px) and (max-width: 991px) {
      margin: 0;
    }
  }

  img {
    width: 100%;
    position: absolute;
  }

  .ui.grid {
    & > .row {
      & > .column.wrapper {
        & > a {
          display: block;
          flex-direction: column;
          flex-grow: 1;
        }

        & > a,
        & > article {
          background-color: var(--bs-mch-white);

          &.${mID}__empty {
            background-color: transparent;

            @media (max-width: 992px) {
              display: none;
              margin: 0;
            }
          }
        }
      }
    }
  }
`

export default StyledRmSignpost
