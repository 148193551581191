import Signpost from '@reusable-modules/Signpost'
import LazyComponent from '@components/LazyComponent'

const InformationComponent = (props) => (
  <LazyComponent
    id={props.id}
  ><Signpost {...props} />
  </LazyComponent>
)

export default InformationComponent